<template>
  <section class="section">
    <nav class="level is-vcentered">
      <div class="level-left">
        <div class="level-item">
          <img class="level-item-img" src="@/assets/icon/dashboard-level.png" alt="Dashboard">
          <h1 class="title">Dashboard</h1>
        </div>
      </div>
    </nav>
    <div class="inner-section">
      <div class="container">
        <div class="column is-12">
          <div class="level">
            <div class="level-left" />
            <div class="level-right">
              <div class="level-item">
                <div class="field field_calendar">
                  <div class="control has-icons-right">
                    <v-date-picker
                      class="input_calendar"
                      clearable
                      is-range
                      mode='range'
                      v-model='dateGraphics'
                      name="dateGraphics"
                      :input-props='{ placeholder: "Rango de Fecha" }'
                    />
                    <span class="icon is-right">
                      <img class="icon_calendar" src="@/assets/icon/calendar.png">
                    </span>
                  </div>
                </div>
              </div>
              <div class="level-right has-text-centered-mobile">
                <button
                  class="button button_ibs is-rounded sm_btn"
                  :class="{'is-loading': loading}"
                  :disabled="!this.dateGraphics"
                  @click="getDataDashboardByDate"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-multiline">
          <template v-if="dashboard.length !== isEmpty">
            <!-- Socio -->
            <div class="column is-6" v-if="dataPartnerServices && !loading">
              <div class="box">
                <div class="columns is-multiline is-centered">
                  <div class="column is-12">
                    <h2 class="subtitle">
                      Servicios por Mes
                    </h2>
                    <bulma-chart
                      v-if="dataPartnerServices && dataPartnerServices.labels.length > 0"
                      :typeChart="bars"
                      :dataChart="dataPartnerServices"
                      :optionsChart="optionsChartBars"
                    />
                    <p v-else>Sin Información</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Cliente -->
            <div class="column is-6" v-if="dataCustomerService && !loading">
              <div class="box">
                <div class="columns is-multiline is-centered">
                  <div class="column is-12">
                    <h2 class="subtitle">
                      Servicios por Mes
                    </h2>
                    <bulma-chart
                      v-if="dataCustomerService && dataCustomerService.labels.length > 0"
                      :typeChart="lineStacked"
                      :dataChart="dataCustomerService"
                      :optionsChart="optionsChartLineStacked"
                    />
                    <p v-else>Sin Información</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Socios -->
            <div v-if="dataCustomerServicePartner && !loading" class="column is-6">
              <div class="box max_h">
                <h2 class="subtitle">
                  Total De Servicios por Clientes
                </h2>
                <bulma-chart
                  v-if="dataCustomerServicePartner && dataCustomerServicePartner.labels.length > 0"
                  :typeChart="pieChart"
                  :dataChart="dataCustomerServicePartner"
                  :optionsChart="{}"
                />
                <p v-else>Sin Información</p>
              </div>
            </div>
            <!-- Clientes -->
            <div v-if="dataCustomerServicesPercentage && !loading" class="column is-6">
              <div class="box max_h">
                <h2 class="subtitle">
                  Total De Servicios
                </h2>
                <bulma-chart
                  v-if="dataCustomerServicesPercentage && dataCustomerServicesPercentage.labels.length > 0"
                  :typeChart="pieChart"
                  :dataChart="dataCustomerServicesPercentage"
                  :optionsChart="{}"
                />
                <p v-else>Sin Información</p>
              </div>
            </div>
            <div class="column is-12">
              <div class="box">
                <div class="columns is-multiline is-centered">
                  <div class="column is-12">
                    <h2 class="subtitle">
                      Últimos 5 Servicios
                    </h2>
                  </div>
                  <div class="column is-12">
                    <base-table
                      class="is-striped-2 is-fullwidth is-hoverable"
                      :columns="this.userType === this.typeCustomer ? columnsData : columnsDataPartner"
                      :data="servicesList"
                      :loading=loading
                    >
                    </base-table>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <no-information/>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  components: {
    NoInformation: () => import('@/components/content/NoInformation.vue'),
    BulmaChart: () => import('@/components/BulmaChart.vue'),
    BaseTable: () => import('@/components/Table.vue')
  },
  data () {
    return {
      dateGraphics: {},
      dashboard: true,
      isEmpty: 0,
      typeCustomer: 1,
      typePartner: 2,
      whoAmI: null,
      page: 1,
      pages: 1,
      total: 1,
      loading: false,
      servicesList: [],
      columnsData: [
        {
          id: 'reference',
          header: 'Referencia Interna',
          accessor: (row) => `${row.reference}`,
          link: true,
          type: 'link',
          route: (row) => ({ name: 'detalle-servicio', params: { serviceId: row.id } })
        },
        { id: 'serviceName', header: 'Servicio', class: 'is-hidden-mobile' },
        { id: 'inspection_date', header: 'Fecha de Inspección', class: 'is-hidden-mobile', accessor: (row) => moment(row.inspection_date).format('DD-MM-YYYY') },
        { id: 'container_number', header: 'No. Contenedor', class: 'column-mobile is-hidden-mobile' },
        { id: 'po_number', header: 'PO', class: 'column-mobile is-hidden-mobile' }
      ],
      columnsDataPartner: [
        {
          id: 'reference',
          header: 'Referencia Interna',
          accessor: (row) => `${row.reference}`,
          link: true,
          type: 'link',
          route: (row) => ({ name: 'detalle-servicio', params: { serviceId: row.id, customerId: row.customer_id } })
        },
        { id: 'serviceName', header: 'Servicio', class: 'is-hidden-mobile' },
        { id: 'customerName', header: 'Cliente', class: 'is-hidden-mobile' },
        { id: 'container_number', header: 'No. Contenedor', class: 'column-mobile is-hidden-mobile' },
        { id: 'po_number', header: 'PO', class: 'column-mobile is-hidden-mobile' }
      ],
      dataCustomerService: null,
      dataPartnerServices: null,
      dataCustomerServicePartner: null,
      dataCustomerServicesPercentage: null,
      lineStacked: 'bar',
      bars: 'bar',
      pieChart: 'pie',
      optionsChartLineStacked: {
        scales: {
          yAxes: [{
            stacked: true
          }]
        }
      },
      optionsChartBars: {
        scales: {
          xAxes: [{
            offset: true,
            gridLines: {
              offsetGridLines: true
            },
            stacked: true
          }],
          yAxes: [{
            offset: true,
            gridLines: {
              offsetGridLines: true
            },
            stacked: true
          }]
        }
      },
      primaryColor: '#2C7EBF',
      secondaryColor: '#58AD36',
      siteColors: [
        '#2C7EBF',
        '#58AD36',
        '#5D6990',
        '#EA92A6',
        '#EB9E23',
        '#E850E8',
        '#935ACB',
        '#A4B3C9',
        '#264653',
        '#2a9d8f',
        '#e9c46a',
        '#e76f51',
        '#e63946',
        '#3d405b',
        '#03045e',
        '#52b788',
        '#343a40',
        '#ffe5d9',
        '#dddf00',
        '#6f4518'
      ],
      startDateOfPreviousMonth: null,
      endDateOfPreviousMonth: null
    }
  },
  computed: {
    ...mapState({
      userType: state => state.authentication.userType
    })
  },
  methods: {
    ...mapActions(['getDashboard']),
    async getDataDashboard (startDate, endDate) {
      const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

      const dashInfo = await this.getDashboard({ startDate: `${startDate}`, endDate: `${endDate}` })
      if (this.userType === this.typeCustomer) {
        const labelsCustomer = []
        const valuesCustomer = []
        if (dashInfo) {
          dashInfo.anualServices.map(element => {
            labelsCustomer.push(`${months[element.month - 1]} ${element.year}`)
            valuesCustomer.push(element.total)
          })
          this.customerServicesConfiguration(labelsCustomer, valuesCustomer, 'Servicios')
          if (dashInfo.servicesTypes) {
            const labelCustomerService = []
            const valuesCustomerService = []
            dashInfo.servicesTypes.map(element => {
              labelCustomerService.push(element.name)
              valuesCustomerService.push(element.total)
            })
            this.customerServicesPercentageConfiguration(labelCustomerService, valuesCustomerService, 'Porcentaje de Servicios')
          }
        }
        this.servicesList = dashInfo.latestServices
      } else {
        const labelsPartner = []
        const valuesParter = []
        if (dashInfo) {
          dashInfo.anualServices.map(element => {
            labelsPartner.push(`${months[element.month - 1]} ${element.year}`)
            valuesParter.push(element.total)
          })
          this.partnerServicesConfiguration(labelsPartner, valuesParter, 'Servicios')
        }
        const labelsServicesCustomer = []
        const valuesServicesCustomer = []
        if (dashInfo) {
          dashInfo.customers.map(element => {
            labelsServicesCustomer.push(element.name)
            valuesServicesCustomer.push(element.total)
          })
          this.customerServicesConfigurationPartner(labelsServicesCustomer, valuesServicesCustomer, 'Clientes')
          this.servicesList = dashInfo.latestServices
        }
      }
      this.loading = false
    },
    async customerServicesConfiguration (labels, data, labelChart) {
      this.dataCustomerService = {
        labels: labels,
        datasets: [{
          backgroundColor: this.primaryColor,
          borderColor: this.primaryColor,
          pointBackgroundColor: this.primaryColor,
          data: data,
          label: labelChart
        }]
      }
    },
    async partnerServicesConfiguration (labels, data, labelChart) {
      this.dataPartnerServices = {
        labels: labels,
        datasets: [{
          backgroundColor: this.secondaryColor,
          borderColor: this.secondaryColor,
          data: data,
          label: labelChart
        }]
      }
    },
    async customerServicesConfigurationPartner (labels, data, labelChart) {
      const color = await this.definedRandomColors(data.length)
      this.dataCustomerServicePartner = {
        labels: labels,
        datasets: [{
          backgroundColor: color,
          borderWidth: 1,
          data: data,
          label: labelChart
        }]
      }
    },
    async customerServicesPercentageConfiguration (labels, data, labelChart) {
      const color = await this.definedRandomColors(data.length)
      this.dataCustomerServicesPercentage = {
        labels: labels,
        datasets: [{
          backgroundColor: color,
          borderWidth: 1,
          data: data,
          label: labelChart
        }]
      }
    },
    dynamicColors () {
      var r = Math.floor(Math.random() * 255)
      var g = Math.floor(Math.random() * 255)
      var b = Math.floor(Math.random() * 255)
      return `rgb(${r}, ${g}, ${b}, 0.8)`
    },
    scaleColors (color, size) {
      const colors = []
      for (let i = 0; i < size; i++) {
        let p = 1
        let temp = ''
        let result = '#'
        const random = Math.random()

        while (p < color.length) {
          temp = parseInt(color.slice(p, p += 2), 16)
          temp += Math.floor((255 - temp) * random)
          result += temp.toString(16).padStart(2, '0')
        }
        colors.push(result)
      }
      return colors
    },
    randomArrayColor (size) {
      const colors = []
      for (let i = 0; i < size; i++) {
        colors.push('#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6))
      }
      return colors
    },
    async definedRandomColors (size) {
      const colors = []
      const sizeArrayColors = this.siteColors.length - 1
      const sizeLimit = Math.ceil(size / this.siteColors.length)
      const limit = []
      for (let j = 1; j < sizeLimit + 1; j++) {
        limit.push(j * this.siteColors.length)
      }

      let localIndex = 0
      for (let i = 0; i < size; i++) {
        if (i > sizeArrayColors) {
          if (limit.includes(i)) {
            localIndex = 0
          } else {
            localIndex = localIndex + 1
          }
        } else {
          localIndex = i
        }
        colors.push(this.siteColors[localIndex])
      }
      return colors
    },
    getDataDashboardByDate () {
      this.loading = true
      const startDate = moment(this.dateGraphics.start).format('YYYY-MM-DD')
      const endDate = moment(this.dateGraphics.end).format('YYYY-MM-DD')
      this.getDataDashboard(startDate, endDate)
    }
  },
  async beforeMount () {
    this.loading = true
    const monthBefore = await moment().subtract(1, 'months').format('MM')
    const yearOfMonthBefore = await moment().subtract(1, 'months').format('YYYY')
    const startDate = await moment([yearOfMonthBefore, monthBefore - 1])
    const endDate = await moment(startDate).endOf('month')
    this.startDateOfPreviousMonth = await moment(startDate).format('YYYY-MM-DD')
    this.endDateOfPreviousMonth = await moment(endDate).format('YYYY-MM-DD')

    await this.getDataDashboard(this.startDateOfPreviousMonth, this.endDateOfPreviousMonth)
  }
}
</script>

<style lang="scss" scoped>
  .section {
    width: 100%;
    min-height: calc(100vh - 90px);
    padding: 0;
  }

  .box {
    min-height: 100%;
  }

  .inner-section {
    display: flex;
    justify-content: center;
  }

  .icon_table_action {
    max-width: 15px;
    margin: 5px;
  }
  .icon_small {
    max-width: 22px;
    margin: 5px;
    cursor: pointer;
  }
  .icon_table_content {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .input_calendar {
    /deep/ input {
      min-width: 210px;
    }
  }

  .max_h .chartjs {
    max-height: 400px;
    max-width: 400px;
    margin: auto;
  }
</style>
